"use client";

import { usePathname, useRouter } from "next/navigation";
import { useEffect } from "react";
import { sendPayloadToRestAPI } from "~/lib/restAPI";
import { IFormData } from "./(diagnostic)/api/error/route";

export default function NotFoundPage() {
	// Determine the path this not-found page is replacing and get a router for routing to a non-standard 404 page that we can control the http code returned for
	const pathname = usePathname();
	const router = useRouter();

	// Log to Splunk via a rest API that the not found page was shown, then navigate to a non-standard 404 page that we can control the http code returned for
	useEffect(() => {
		async function logNotFoundPageShownThenNavigateToSpecial404() {
			await sendPayloadToRestAPI<IFormData>(
				{
					pathname: pathname,
				},
				"/api/not-found",
			);
		}
		void logNotFoundPageShownThenNavigateToSpecial404();
		router.push("/page-not-found");
	}, [pathname, router]);

	// Show the component.
	return <></>;
}

// I wish there were a standard way, but nextjs is still hard coded to have a status of 200 for streamed responses, even if some child component of that streamed response ends up generating not found or error pages.
// So following the workaround suggested here were we have a non-standard 404 and 500 page that we can control the http code returned for:
// https://github.com/vercel/next.js/discussions/53225
// See also this resource about streaming: https://nextjs.org/learn/dashboard-app/streaming
// And see this resource about how nextjs returns a 200 code for streaming responses: https://nextjs.org/docs/app/api-reference/file-conventions/not-found
